<template>
  <div>
<!--    <input v-model="senderId"/>-->
<!--    <button v-on:click="init()">连接</button>-->
    <input v-model="receiverId" placeholder="接收者id"/>
    <input v-model="content" placeholder="发送内容"/>
    <button v-on:click="send()">发送</button>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'Ws',
  data () {
    return {
      ws: '',
      initData: {
        actionType: 1,
        msgVo: {
          senderId: ''
        }
      },
      senderId: '',
      content: '',
      receiverId: '',
      handler: {}
    }
  },
  mounted () {
  },
  methods: {
    // init () {
    //   console.log(this.senderId)
    //   this.ws = new WebSocket('ws://192.168.10.18:8888/ws')
    //   this.ws.onopen = this.onopen()
    //   this.ws.onmessage = this.onmessage()
    //   this.ws.onclose = this.onclose()
    //   this.ws.onerror = this.onerror()
    // },
    send () {
      const msg = {}
      msg.actionType = 2
      msg.msgVo = {}
      msg.msgVo.senderId = store.state.userInfo.id
      msg.msgVo.receiverId = this.receiverId
      msg.msgVo.content = this.content
      store.state.ws.send(JSON.stringify(msg))
    }
    // onopen () {
    //   const that = this
    //   const initData = {}
    //   initData.actionType = 1
    //   initData.msgVo = {}
    //   initData.msgVo.senderId = that.senderId
    //   that.ws.send(JSON.stringify(initData))
    //   console.log('open')
    //   clearInterval(that.handler)
    //   that.handler = setInterval(function () {
    //     console.log(that.ws.readyState)
    //     if (that.ws.readyState === 1) {
    //       initData.actionType = 4
    //       that.ws.send(JSON.stringify(initData))
    //     } else {
    //       that.ws.close()
    //       setTimeout(function () {
    //         that.init()
    //       }, 1500)
    //     }
    //   }, 5000)
    // },
    // onerror () {
    //   console.log('error')
    // },
    // onclose () {
    //   console.log()
    // },
    // onmessage () {
    //   console.log('message: ' + event.data)
    // }
  }
}
</script>

<style scoped>

</style>
